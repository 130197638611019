












































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    OParticipantEditInfo: () =>
      import(
        "@/components/organisms/event/participant/o-participant-edit-info.vue"
      ),
    MParticipantEditRegistrationFees: () =>
      import(
        "@/components/molecules/participant/m-participant-edit-registration-fees.vue"
      ),
    MParticipantAdditionalServicesEdit: () =>
      import(
        "@/components/molecules/participant/m-participant-additional-services-edit.vue"
      ),
    MParticipantEditAccommodation: () =>
      import(
        "@/components/molecules/participant/m-participant-edit-accommodation.vue"
      ),
    MParticipantEditPayments: () =>
      import(
        "@/components/molecules/participant/m-participant-edit-payments.vue"
      ),
  },

  setup(_, { root }) {
    const state = reactive({
      loading: false,
      success: false,
      error: false,
      participantPhoto: "",
      oldImage: null as null | { id: string },
      empty: false,
      table: false,
      loaded: false,
      items: [],
      participant: {},
      currency: "",
      eventDegrees: [],
      eventType: "",
      participantServices: [],
      panels: 0,
      participantFees: [],
      updateId: "",
      participantAccommodation: {},
      enabledModules: {
        accommodationModuleEnabled: false,
        additionalServiceModuleEnabled: false,
        surveyModuleEnabled: false,
      },
      accommodationFromDate: "",
      accommodationToDate: "",
    });

    //  Start fetch single participant data

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`participant/${root.$route.params.ptid}`)
        .then(({ data: { participant } }) => {
          state.oldImage = participant.photo || undefined;
          state.participant = participant;
          state.participantServices = participant.services;
          state.participantFees = participant.participantRegistrationFeeTerm;
          state.participantAccommodation = participant.accommodation;
          state.updateId = uuid();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    //  End fetch single participant data

    // Fetch event

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/basic`)
        .then(({ data: { event } }) => {
          state.currency = event.currency;
          state.eventDegrees = event.degrees ?? [];
          state.eventType = event.type;
          state.enabledModules.accommodationModuleEnabled =
            event.accommodationModuleEnabled;
          state.enabledModules.additionalServiceModuleEnabled =
            event.additionalServiceModuleEnabled;
          state.enabledModules.surveyModuleEnabled = event.surveyModuleEnabled;
          state.accommodationFromDate = event.accommodationModule.from.split(
            "T"
          )[0];
          state.accommodationToDate = event.accommodationModule.to.split(
            "T"
          )[0];
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    const filesUrl = computed(
      () => `${root.$store.state.api.baseURL}/static/participant/`
    );

    return { state, filesUrl, fetchData };
  },
});
